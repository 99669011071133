@import (reference) '~@zola/zola-ui/src/styles/common/_colorsBaby.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: @spacing-24;
  grid-template-areas:
    'item1 item2 item3'
    'item4 item5 item6';
  .not-desktop-v2({
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
      'item1 item4'
      'item6 item3'
      'item5 item2';
    gap: @spacing-16;
  });
  .mobile({
    gap: @spacing-xs;
  });

  .tile {
    position: relative;
  }
  .tile:nth-child(1) {
    grid-area: item1;
  }
  .tile:nth-child(2) {
    grid-area: item2;
  }
  .tile:nth-child(3) {
    grid-area: item3;
  }
  .tile:nth-child(4) {
    grid-area: item4;
  }
  .tile:nth-child(5) {
    grid-area: item5;
  }
  .tile:nth-child(6) {
    grid-area: item6;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 215px;
    .desktop-v2({
      font-size: 42px;
      line-height: 52px;
      width: 285px;
    });
    .mobile-xs({
      font-size: @zui-fontsize-small;
      line-height: 20px;
      width: 110px;
    });
  }

  .text1 {
    color: @color-pd-ultramarine-100;
    left: 45%;
  }

  .terms {
    display: block;
    .mobile({
      font-size: @zui-fontsize-extra-small;
    });
  }
}
