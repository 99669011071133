@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.homepageContent {
  max-width: 1600px;
  display: grid;
  gap: @spacing-32;
  margin: 0 auto;
  padding: 0 @spacing-xs;

  .desktop-lg-v2({
    gap: 64px;
    padding: 0 @spacing-lg;
  });
}
