@import (reference) '~@zola/zola-ui/src/styles/common/_colorsBaby.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.hero {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  height: 600px;
  .tablet({
    height: 450px;
  });
  .mobile({
    height: 320px;
  });
}

.heroMediaWrapper {
  height: 600px;
  .tablet({
    height: 450px;
  });
  .mobile({
    height: 320px;
  });
}

.heroImg {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.heroVideo {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, @color-tan-100 100%);
  opacity: 0.2;
}

.heroContentWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 @spacing-xl;
  .tablet({
    padding: 0 @spacing-lg;
  });
  .mobile({
    justify-content: end;
    padding: 0 @spacing-16 @spacing-16;
  });
}

.title {
  color: @color-tan-cloud-100;
  max-width: 540px;
  margin-bottom: @spacing-16;
  .tablet({
    max-width: 340px;
    font-size: 42px;
    line-height: 42px;
  });
  .mobile({
    max-width: 260px;
    margin-bottom: @spacing-24;
    font-size: @zui-fontsize-larger;
    line-height: 32px;
  });
}

.heroList {
  display: flex;
  flex-direction: column;
  gap: @spacing-sm;
  margin: 0;
  .mobile({
    display: none;
  });
}

.heroListItem {
  display: flex;
  align-items: center;
  gap: @spacing-16;
  font-size: @zui-fontsize-regular;
  color: @color-tan-cloud-100;
}

.buttonWrapper {
  display: flex;
  gap: @spacing-16;
  margin-top: @spacing-32;
  .mobile({
    margin-top: 0;
    gap: @spacing-xs;
    a {
      flex: 1;
    }
  });
}

.registryButton {
  color: @color-tan-cloud-100 !important;
  background-color: fade(@color-tan-100, 50%) !important;
  &:active,
  &:focus,
  &:hover {
    background-color: fade(@color-tan-100, 75%) !important;
  }
}

.heroMobileList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: @spacing-16 0 0;
  .not-mobile({
    display: none;
  });
}

.heroMobileListItem {
  justify-items: center;
  text-align: center;
  padding: 0 @spacing-xxs;
  font-size: @zui-fontsize-smaller;
  color: @color-tan-080;
  &:not(:last-of-type) {
    border-right: 1px solid @color-tan-005;
  }
}

.heroMobileListItemText {
  max-width: 105px;
}
