@import (reference) '~@zola/zola-ui/src/styles/common/_colorsBaby.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.findRegistry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: @spacing-24;
  padding: 0 @spacing-24;
  border-radius: var(--support-accordion-border-radius, 16px);
  height: 665px;
  background-color: @color-teal-sage-100;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('~/public/static-baby/images/homepage/find_registry_desktop.webp');
  .tablet({
    height: 420px;
    background-image: url('~/public/static-baby/images/homepage/find_registry_tablet.webp');
  });
  .mobile({
    gap: @spacing-16;
    height: 276px;
    background-size: 45%;
    background-position: bottom right;
    background-image: url('~/public/static-baby/images/homepage/find_registry_mobile.webp');
  });
  .not-mobile({
    align-items: center;
  });

  .title {
    .tablet({
      font-size: 42px;
      width: 500px;
      text-align: center;
    });
    .mobile({
      font-size: @zui-fontsize-large;
    });
    .mobile-xs({
      width: 230px;
    });
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @spacing-24;
    .mobile({
      align-items: start;
      gap: @spacing-16;
      > div {
        width: 100%;
      }
    });

    input {
      border-radius: 100px;
      height: 56px;
      width: 568px;
      .tablet({
        width: 400px;
      });
      .mobile({
        width: 100%;
      });
    }

    svg {
      color: var(--text-primary);
      top: 20px;
    }
  }
}
