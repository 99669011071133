@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.shopByCategories {
  padding: 0 @spacing-xs;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .headerLink {
    text-decoration: none;
    .mobile({
      font-size: @zui-fontsize-smaller;
    });
  }

  .categoryTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @spacing-xxs;
    color: var(--text-primary);
    font-weight: @font-weight-semi-bold;
    text-align: center;
    transition: transform 0.3s ease-in-out;

    &:hover,
    &:focus {
      text-decoration: none;
      transform: scale(1.1);
    }

    .not-desktop-v2({
      font-size: @zui-fontsize-smaller;
      text-wrap: auto;
    });
  }

  .categoryImg {
    height: 180px;
    width: 180px;
    border-radius: @spacing-24;
    .tablet({
      height: 140px;
      width: 140px;
    });
    .mobile({
      height: 100px;
      width: 100px;
    });
  }
}
