@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.faq {
  .title {
    .tablet({
      font-size: 42px;
    });
    .mobile({
      font-size: @zui-fontsize-large;
      margin-right: auto;
    });
  }
  > div > div > div {
    padding: 0;
    > div {
      margin: 0;
      padding: @spacing-32 @spacing-24;
      .not-mobile({
        padding: @spacing-xl @spacing-lg;
      });
      .desktop-lg-v2({
        padding: 104px 0;
      });
    }
  }
}
