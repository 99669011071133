@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.brands {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .desktop-xl-v2({
    justify-content: space-between;
  });

  .logo {
    height: 80px;
    .tablet({
      height: 72px;
    });
    .mobile({
      height: 60px;
    });
  }
}
